<template>
  <div v-if="show" :class="['set-config', $root.config.theme]">
    <div class="config-box">
      <div class="set-box">
        <div class="box-bg">
          <div class="version">
            <svg-icon icon-class="new-logo3" class-name="tabs-svg" /><span
              >V{{ version }}</span
            >
          </div>
        </div>
        <el-form label-position="top">
          <el-tabs
            v-model="activeName"
            @tab-click="tabsHandleClick"
            tab-position="left"
            style="height: 520px; padding: 40px 0 20px 0"
            class="tabs"
          >
            <el-tab-pane name="tree">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="041" class-name="tabs-svg" />
                  <span>主题设置</span>
                </span>
              </template>
              <el-form-item label="主题样式">
                <el-radio-group v-model="config.themeType">
                  <el-radio
                    label="dark"
                    @click="
                      config.theme = 'dark7';
                      config.mindThemeColor = 'blue';
                    "
                    >暗色</el-radio
                  >
                  <el-radio
                    label="light"
                    @click="
                      config.theme = 'default';
                      config.mindThemeColor = 'black';
                    "
                    >浅色</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <div v-show="config.themeType === 'dark'">
                <el-form-item label="树目录样式">
                  <el-radio-group v-model="config.theme">
                    <el-radio label="dark7">暗色</el-radio>
                    <!-- <el-radio label="dark6">暗色</el-radio>
                    <el-radio label="dark2">暗紫</el-radio>
                    <el-radio label="dark">暗蓝</el-radio>
                    <el-radio label="dark5">暗蓝3</el-radio> -->
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="思维导图线条颜色">
                  <el-radio-group v-model="config.mindThemeColor">
                    <el-radio label="blue">蓝色</el-radio>
                    <el-radio label="purple">紫色</el-radio>
                    <el-radio label="grey">灰色</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div v-show="config.themeType === 'light'">
                <el-form-item label="树目录样式">
                  <el-radio-group v-model="config.theme">
                    <el-radio label="default">黑色</el-radio>
                    <el-radio label="light2">灰色</el-radio>
                    <el-radio label="light3">白色</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="思维导图线条颜色">
                  <el-radio-group v-model="config.mindThemeColor">
                    <el-radio label="black">黑色</el-radio>
                    <el-radio label="blue">蓝色</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="个人信息">个人信息</el-tab-pane> -->
            <el-tab-pane name="config" style="width: 500px">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="042" class-name="tabs-svg" />
                  <span>存量设置</span>
                </span>
              </template>
              <el-scrollbar max-height="420px" always>
                <el-form-item label="选择合适页宽">
                  <el-radio-group v-model="config.isWideScreen">
                    <el-radio label="default">默认</el-radio>
                    <el-radio label="medium">较宽</el-radio>
                    <el-radio label="large">全宽</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="是否显示标题下分割线">
                  <el-radio-group v-model="config.isTitleLine">
                    <el-radio label="true">显示</el-radio>
                    <el-radio label="false">不显示</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="存量是否滚动到末尾">
                  <el-radio-group v-model="config.scrollPosition">
                    <el-radio label="false">否</el-radio>
                    <el-radio label="true">是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="存量工具栏模式">
                  <el-radio-group v-model="config.toolbarType">
                    <el-radio label="default">跟随光标</el-radio>
                    <el-radio label="top">固定顶部</el-radio>
                    <el-radio label="bottom">固定底部</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="存量粘贴模式">
                  <el-radio-group v-model="config.pastePlain">
                    <el-radio label="true">清除格式</el-radio>
                    <el-radio label="false">不清除格式</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="存量默认字体大小">
                  <div class="color-block">
                    <span class="demonstration" style="margin-right: 26px"
                      >示例</span
                    >
                    <el-tooltip
                      effect="dark"
                      content="12~72，默认15"
                      placement="bottom"
                    >
                      <div>
                        <el-input-number
                          v-model="config.fontSize"
                          controls-position="right"
                          :min="12"
                          :max="72"
                          value-on-clear="min"
                          class="mc-input"
                        />
                      </div>
                    </el-tooltip>
                  </div>
                </el-form-item>
                <el-form-item label="存量默认段间距、行间距">
                  <div class="color-block">
                    <span class="demonstration" style="margin-right: 26px"
                      >段间距</span
                    >
                    <el-tooltip
                      effect="dark"
                      content="0~1.2，默认0.8"
                      placement="bottom"
                    >
                      <div>
                        <el-input-number
                          v-model="config.segmentSpacing"
                          controls-position="right"
                          :precision="1"
                          :step="0.1"
                          :min="0"
                          :max="1.2"
                          value-on-clear="min"
                          class="mc-input"
                        />
                      </div>
                    </el-tooltip>
                  </div>
                  <div class="color-block">
                    <span class="demonstration" style="margin-right: 26px"
                      >行间距</span
                    >
                    <el-tooltip
                      effect="dark"
                      content="1~2，默认1.5"
                      placement="bottom"
                    >
                      <div>
                        <el-input-number
                          v-model="config.lineHeight"
                          controls-position="right"
                          :precision="1"
                          :step="0.1"
                          :min="1"
                          :max="2"
                          value-on-clear="min"
                          class="mc-input"
                        />
                      </div>
                    </el-tooltip>
                  </div>
                </el-form-item>
                <el-form-item label="自定义按钮设置">
                  <div class="color-block">
                    <span
                      class="demonstration"
                      :style="[
                        { color: config.textColor },
                        { 'background-color': config.textColorBg },
                        { 'font-weight': 'bold' },
                        { 'border-radius': '4px' },
                        { padding: '0 5px' },
                      ]"
                      >重要内容</span
                    >
                    <el-tooltip
                      effect="dark"
                      content="字体颜色修改"
                      placement="bottom"
                    >
                      <div>
                        <el-color-picker
                          v-model="config.textColor"
                          :predefine="predefineColors"
                        />
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      effect="dark"
                      content="字体背景修改"
                      placement="bottom"
                    >
                      <div>
                        <el-color-picker
                          v-model="config.textColorBg"
                          :predefine="predefineColors"
                        />
                      </div>
                    </el-tooltip>
                  </div>
                  <div class="color-block">
                    <span
                      class="demonstration"
                      :style="[
                        { color: config.textBgColor },
                        { 'background-color': config.textBgBgColor },
                        { 'border-radius': '4px' },
                        { padding: '0 5px' },
                      ]"
                      >非常重要</span
                    >
                    <el-tooltip
                      effect="dark"
                      content="字体颜色修改"
                      placement="bottom"
                    >
                      <div>
                        <el-color-picker
                          v-model="config.textBgColor"
                          :predefine="predefineColors"
                        />
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      effect="dark"
                      content="字体背景修改"
                      placement="bottom"
                    >
                      <div>
                        <el-color-picker
                          v-model="config.textBgBgColor"
                          :predefine="predefineColors"
                        />
                      </div>
                    </el-tooltip>
                  </div>
                </el-form-item>
                <el-form-item label="存量工具栏按钮">
                  <el-scrollbar
                    max-height="200px"
                    always
                    view-class="scrollbar-box"
                  >
                    <draggable v-model="toolbarButtons">
                      <div
                        class="checkbox-group-btn"
                        v-for="(item, index) in toolbarButtons"
                        :key="index"
                      >
                        <el-checkbox
                          v-model="item.checked"
                          :label="item.value"
                          :checked="item.checked"
                          >{{ item.label }}
                        </el-checkbox>
                        <div style="float: right">
                          <el-icon>
                            <Operation color="#bbbbbb" />
                          </el-icon>
                        </div>
                      </div>
                    </draggable>
                  </el-scrollbar>
                </el-form-item>
                <el-form-item label="存量右键菜单设置">
                  <el-scrollbar
                    max-height="200px"
                    view-class="scrollbar-box menu-scrollbar"
                  >
                    <div
                      class="checkbox-group-btn"
                      v-for="(item, index) in editorMenuButtons"
                      :key="index"
                    >
                      <el-checkbox
                        v-model="item.checked"
                        :label="item.value"
                        :checked="item.checked"
                        >{{ item.label }}
                      </el-checkbox>
                    </div>
                  </el-scrollbar>
                </el-form-item>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane name="mind">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="005" class-name="tabs-svg" />
                  <span>导图设置</span>
                </span>
              </template>
              <el-scrollbar max-height="420px" always>
                <el-form-item label="导图默认展开层级(1~15)">
                  <div class="color-block">
                    <el-input-number
                      v-model="config.mindCollapse"
                      controls-position="right"
                      :min="1"
                      :max="15"
                      value-on-clear="min"
                      class="mc-input"
                    />
                  </div>
                </el-form-item>
                <el-form-item label="导图线条长度(15~30)">
                  <div class="color-block">
                    <el-input-number
                      v-model="config.mindhGap"
                      controls-position="right"
                      :min="15"
                      :max="30"
                      value-on-clear="25"
                      class="mc-input"
                    />
                  </div>
                </el-form-item>
                <el-form-item label="导图节点上下间距(2~12)">
                  <div class="color-block">
                    <el-input-number
                      v-model="config.mindvGap"
                      controls-position="right"
                      :min="2"
                      :max="12"
                      value-on-clear="8"
                      class="mc-input"
                    />
                  </div>
                </el-form-item>
                <el-form-item label="工具栏位置">
                  <el-radio-group v-model="config.mindToolbarPosition">
                    <!-- <el-radio label="left">左侧</el-radio> -->
                    <el-radio label="bottom">底部</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="切换目录时导图状态">
                  <el-radio-group v-model="config.alwaysMind">
                    <el-radio label="false">导图整体关闭</el-radio>
                    <el-radio :label="4">导图内容不变</el-radio>
                    <el-radio :label="3">导图内容跟随</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="双开导图布局">
                  <el-radio-group v-model="config.mindQuadtreeType">
                    <el-radio label="ver">上下</el-radio>
                    <el-radio label="hor">左右</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!--                <el-form-item label="存量是否常开">-->
                <!--                  <el-radio-group v-model="config.alwaysEditor">-->
                <!--                    <el-radio label="true">常开</el-radio>-->
                <!--                    <el-radio label="false">关闭</el-radio>-->
                <!--                  </el-radio-group>-->
                <!--                </el-form-item>-->
                <el-form-item label="导图右键菜单设置">
                  <el-scrollbar
                    max-height="200px"
                    view-class="scrollbar-box menu-scrollbar"
                  >
                    <div
                      class="checkbox-group-btn"
                      v-for="(item, index) in mindMenuButtons"
                      :key="index"
                    >
                      <el-checkbox
                        v-model="item.checked"
                        :label="item.value"
                        :checked="item.checked"
                        >{{ item.label }}
                      </el-checkbox>
                    </div>
                  </el-scrollbar>
                </el-form-item>
                <el-form-item label="迁移双链功能">
                  <el-radio-group v-model="config.edgeEnabled">
                    <el-radio label="false">启用</el-radio>
                    <el-radio label="true">不启用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="双链线条是否显示">
                  <el-radio-group v-model="config.mindHideEdgeLink">
                    <el-radio label="false">显示</el-radio>
                    <el-radio label="true">不显示</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="双链标签是否显示">
                  <el-radio-group v-model="config.mindEdgeShowLabel">
                    <el-radio label="true">显示</el-radio>
                    <el-radio label="false">不显示</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane name="menu">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="044" class-name="tabs-svg" />
                  <span>菜单设置</span>
                </span>
              </template>
              <el-form-item label="模型树菜单设置">
                <el-scrollbar
                  max-height="400px"
                  view-class="scrollbar-box menu-scrollbar"
                >
                  <div
                    class="checkbox-group-btn"
                    v-for="(item, index) in menuButtons"
                    :key="index"
                  >
                    <el-checkbox
                      v-model="item.checked"
                      :label="item.value"
                      :checked="item.checked"
                      >{{ item.label }}
                    </el-checkbox>
                  </div>
                </el-scrollbar>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane name="navbar">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="052" class-name="tabs-svg" />
                  <span>导航设置</span>
                </span>
              </template>
              <el-form-item label="模型树导航条设置">
                <el-scrollbar
                  max-height="400px"
                  view-class="scrollbar-box menu-scrollbar"
                >
                  <div
                    class="checkbox-group-btn"
                    v-for="(item, index) in navbarButtons"
                    :key="index"
                  >
                    <el-checkbox
                      v-model="item.checked"
                      :label="item.value"
                      :checked="item.checked"
                      >{{ item.label }}
                    </el-checkbox>
                  </div>
                </el-scrollbar>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane name="tips">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="045" class-name="tabs-svg" />
                  <span>出处设置</span>
                </span>
              </template>
              <div class="tips-input" style="display: flex">
                <el-input
                  v-model="tipsinput"
                  placeholder="添加文本"
                  style="margin-right: 40px"
                />
                <el-button
                  type="primary"
                  @click="saveTips"
                  :disabled="tipsinput == ''"
                  >储存
                </el-button>
              </div>
              <el-divider></el-divider>
              <div>
                <el-scrollbar max-height="330px">
                  <draggable v-model="$root.fontList" @end="datadragEnd">
                    <div v-for="(item, index) in $root.fontList" :key="index">
                      <div v-if="item.isfouce" class="tips-infobox">
                        <div class="tips-info" v-if="item.isEdit">
                          <el-input
                            v-model="item.tip"
                            :id="'tips' + item.id"
                            class="group-input"
                            @blur="onTipsEdit(item)"
                            @focus="onTipsFocus(item)"
                            @keydown.enter="onEnter(item)"
                          ></el-input>
                        </div>
                        <div class="tips-info" v-else>
                          {{ item.tip }}
                        </div>
                        <div>
                          <el-icon @click="onTipsEdit(item)">
                            <Edit color="#bbbbbb" />
                          </el-icon>
                        </div>
                        <div style="padding-left: 10px">
                          <el-icon @click="removeTip(item.id)">
                            <Delete color="#F54949" />
                          </el-icon>
                        </div>
                        <div style="padding-left: 10px">
                          <el-icon>
                            <Operation color="#bbbbbb" />
                          </el-icon>
                        </div>
                      </div>
                      <div
                        v-else
                        @mouseover="focusfont(item)"
                        class="tips-info tip-tip"
                      >
                        {{ item.tip }}
                      </div>
                    </div>
                  </draggable>
                </el-scrollbar>
              </div>
            </el-tab-pane>
            <el-tab-pane name="groups">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="051" class-name="tabs-svg" />
                  <span>分组设置</span>
                </span>
              </template>
              <div class="tips-input" style="display: flex">
                <el-input
                  v-model="groupNameInput"
                  placeholder="添加分组"
                  style="margin-right: 40px"
                />
                <el-button
                  type="primary"
                  @click="onAddGroup"
                  :disabled="groupNameInput == ''"
                  >储存
                </el-button>
              </div>
              <el-divider></el-divider>
              <div>
                <el-scrollbar max-height="330px">
                  <draggable v-model="$root.groups" @end="groupsDragEnd">
                    <div v-for="(item, index) in $root.groups" :key="index">
                      <div v-if="item.isfouce" class="tips-infobox">
                        <div class="tips-info" v-if="item.isEdit">
                          <el-input
                            v-model="item.groupName"
                            :id="item.id"
                            class="group-input"
                            @keyup.enter="onGroupEdit(item)"
                            @blur="updateGroup(item)"
                          ></el-input>
                        </div>
                        <div class="tips-info" v-else>
                          {{ item.groupName }}
                        </div>
                        <div>
                          <el-icon @click="onGroupEdit(item)">
                            <Edit color="#bbbbbb" />
                          </el-icon>
                        </div>
                        <div style="padding-left: 10px">
                          <el-icon @click="onDeleteGroup(item)">
                            <Delete color="#F54949" />
                          </el-icon>
                        </div>
                        <div style="padding-left: 10px">
                          <el-icon>
                            <Operation color="#bbbbbb" />
                          </el-icon>
                        </div>
                      </div>
                      <div
                        v-else
                        @mouseover="focusGroups(item)"
                        class="tips-info tip-tip"
                      >
                        {{ item.groupName }}
                      </div>
                    </div>
                  </draggable>
                </el-scrollbar>
              </div>
            </el-tab-pane>
            <el-tab-pane name="transfer">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="047" class-name="tabs-svg" />
                  <span>中转设置</span>
                </span>
              </template>
              <div class="tips-input" style="display: flex">
                <el-input
                  v-model="relationInput"
                  placeholder="添加关系"
                  style="margin-right: 40px"
                  minlength="1"
                  maxlength="4"
                />
                <el-button
                  type="primary"
                  @click="saveRelation"
                  :disabled="relationInput == ''"
                  >储存
                </el-button>
              </div>
              <el-divider></el-divider>
              <div>
                <el-scrollbar max-height="330px">
                  <draggable
                    v-model="$root.relationList"
                    @end="relationDragEnd"
                  >
                    <div
                      v-for="(item, index) in $root.relationList"
                      :key="index"
                    >
                      <div v-if="item.isfouce" class="tips-infobox">
                        <div class="tips-info" v-if="item.isEdit">
                          <el-input
                            v-model="item.data"
                            :id="'relation' + item.id"
                            class="group-input"
                            @keyup.enter="onRelationEdit(item)"
                          ></el-input>
                        </div>
                        <div class="tips-info" v-else>
                          {{ item.data }}
                        </div>
                        <div>
                          <el-icon @click="onRelationEdit(item)">
                            <Edit color="#bbbbbb" />
                          </el-icon>
                        </div>
                        <div style="padding-left: 10px">
                          <el-icon @click="removeRelation(item.id)">
                            <Delete color="#F54949" />
                          </el-icon>
                        </div>
                        <div style="padding-left: 10px">
                          <el-icon>
                            <Operation color="#bbbbbb" />
                          </el-icon>
                        </div>
                      </div>
                      <div
                        v-else
                        @mouseover="focusRelation(item)"
                        class="tips-info tip-tip"
                      >
                        {{ item.data }}
                      </div>
                    </div>
                  </draggable>
                </el-scrollbar>
              </div>
            </el-tab-pane>
            <el-tab-pane name="bindAccount">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="046" class-name="tabs-svg" />
                  <span>账号管理</span>
                </span>
              </template>
              <el-scrollbar max-height="420px" always>
                <el-form-item label="账号管理">
                  <div class="acc-box">
                    <div
                      class="acc-i acc-d"
                      style="padding: 12px 0; height: 66px"
                    >
                      <div class="acc-l">头像</div>
                      <div class="acc-r" @click="avatarUploadVisible = true">
                        <el-avatar
                          :size="40"
                          v-if="user && user.headImage"
                          :src="user.headImage"
                        />
                        <div class="acc-b">
                          <el-icon size="24px"><ArrowRight /></el-icon>
                        </div>
                      </div>
                    </div>
                    <div class="acc-i acc-d">
                      <div class="acc-l">昵称</div>
                      <div class="acc-r" @click="changeName">
                        {{ user.nickName }}
                        <div class="acc-b">
                          <el-icon size="24px"><ArrowRight /></el-icon>
                        </div>
                      </div>
                    </div>
                    <div class="acc-i">
                      <div class="acc-l">树友ID</div>
                      <div class="acc-r">
                        {{ user.accountId }}
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="acc-box">
                    <div class="acc-i acc-d">
                      <div class="acc-l">手机</div>
                      <div class="acc-r">{{ mobile }}</div>
                    </div>
                    <div class="acc-i">
                      <div class="acc-l">微信</div>
                      <el-tooltip
                        effect="dark"
                        content="绑定新微信"
                        placement="bottom"
                      >
                        <div
                          v-if="$store.state.isWechatAccount"
                          class="acc-r"
                          @click="wxGetCode"
                        >
                          已绑定
                        </div>
                        <div v-else class="acc-r" @click="wxGetCode">
                          未绑定
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </el-form-item>
                <div
                  v-show="isWxLogin == true"
                  id="login_container"
                  class="login-form"
                ></div>
                <el-form-item>
                  <div class="acc-box">
                    <div :class="['acc-i', { 'acc-d': pwdBox }]">
                      <div class="acc-l">设置密码（登录账号为手机号）</div>
                      <div v-if="isPwdOpen" class="acc-r" @click="pwdLogin">
                        修改密码
                      </div>
                      <div v-else class="acc-r" @click="pwdLogin">
                        开启密码登录
                      </div>
                    </div>
                    <div v-if="pwdBox" class="pwd-box">
                      <div v-if="isPwdOpen" class="tips-input pwd-d">
                        <el-input
                          v-model="originPwd"
                          ref="originPwd"
                          type="password"
                          placeholder="输入原密码"
                          minlength="8"
                          maxlength="16"
                          show-password
                          style="width: 200px"
                        />
                      </div>
                      <div v-if="false" class="tips-input pwd-d">
                        <el-tooltip
                          v-show="false"
                          effect="dark"
                          content="输入由英文字母或数字组成的8~16位用户名"
                          placement="right"
                        >
                          <el-input
                            v-model="accountName"
                            ref="accountName"
                            placeholder="输入用户名"
                            minlength="8"
                            maxlength="16"
                            style="width: 200px"
                          />
                        </el-tooltip>
                      </div>
                      <div class="tips-input pwd-d">
                        <el-tooltip
                          effect="dark"
                          content="输入至少包含字母、数字、特殊字符任意两种组成的8~16位密码"
                          placement="right"
                        >
                          <el-input
                            v-model="password"
                            ref="password"
                            type="password"
                            placeholder="输入新密码"
                            minlength="8"
                            maxlength="16"
                            show-password
                            style="width: 200px"
                          />
                        </el-tooltip>
                      </div>
                      <div class="tips-input">
                        <el-input
                          v-model="rePassword"
                          ref="rePassword"
                          type="password"
                          placeholder="再次输入新密码"
                          minlength="8"
                          maxlength="16"
                          show-password
                          style="width: 200px; margin-right: 16px; float: left"
                        />
                        <el-button
                          type="primary"
                          class="ac-logout"
                          @click="submitPwdOpen"
                          style="float: left"
                        >
                          确认密码
                        </el-button>
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <div>
                  <el-button
                    type="primary"
                    class="ac-logout"
                    @click="onLoginOut"
                  >
                    退出登录
                  </el-button>
                </div>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane name="laboratory">
              <template #label>
                <span class="tabs-label">
                  <svg-icon icon-class="040" class-name="tabs-svg" />
                  <span>实验功能</span>
                </span>
              </template>
              <el-scrollbar max-height="420px" always>
                <el-form-item label="快捷菜单默认入口">
                  <el-radio-group v-model="shortcutMenu.location">
                    <el-radio label="bottom">右下角</el-radio>
                    <el-radio label="top">右上角</el-radio>
                    <el-radio label="none">不启用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="快捷菜单默认打开方式">
                  <el-radio-group v-model="shortcutMenu.viewLocation">
                    <!-- <el-radio label="fixed">弹窗模式</el-radio> -->
                    <el-radio label="right">侧边模式</el-radio>
                    <el-radio label="full">弹窗模式</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div>
                  <el-form-item label="闪念树" v-show="isVip">
                    <el-radio-group v-model="shortcutMenu.flashThought">
                      <el-radio label="true">启用</el-radio>
                      <el-radio label="false">不启用</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="AI 助手" v-show="isVip">
                    <el-radio-group v-model="shortcutMenu.chatgpt">
                      <el-radio label="true">启用</el-radio>
                      <el-radio label="false">不启用</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="中转站" v-show="false">
                    <el-radio-group v-model="shortcutMenu.transfer">
                      <el-radio label="true">启用</el-radio>
                      <el-radio label="false">不启用</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <el-form-item label="AI 英语" v-show="isVip">
                  <el-radio-group v-model="config.aiEnglish">
                    <el-radio label="true">启用</el-radio>
                    <el-radio label="false">不启用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="自动锁屏">
                  <el-radio-group v-model="config.lock.status">
                    <el-radio label="true">启用</el-radio>
                    <el-radio label="false">不启用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div v-show="config.lock.status === 'true'">
                  <el-form-item label="无操作自动锁屏">
                    <div class="color-block">
                      <el-input-number
                        v-model="config.lock.lockedTime"
                        controls-position="right"
                        :min="10"
                        value-on-clear="min"
                        class="mc-input"
                      /><span class="demonstration" style="margin-left: 10px"
                        >秒</span
                      >
                    </div>
                  </el-form-item>
                  <div class="tips-input">
                    <el-form-item label="锁屏密码">
                      <el-input
                        v-model="lockPassword"
                        type="password"
                        placeholder="输入密码"
                        show-password
                        style="width: 200px"
                      />
                    </el-form-item>
                  </div>
                </div>
                <el-form-item label="第三方插件" v-show="false">
                  <el-radio-group v-model="shortcutMenu.chatgpt">
                    <el-radio label="true">启用</el-radio>
                    <el-radio label="false">不启用</el-radio>
                  </el-radio-group>
                  <div
                    class="tips-input"
                    style="width: 95%"
                    v-if="shortcutMenu.chatgpt === 'true'"
                  >
                    <el-input
                      v-model="shortcutMenu.iframe"
                      placeholder="支持第三方插件调用"
                      type="textarea"
                      :rows="6"
                      resize="none"
                      max="220"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="存量工具栏AI 助手快捷按钮" v-show="false">
                  <el-radio-group v-model="config.toolbarRobots">
                    <el-radio label="true">启用</el-radio>
                    <el-radio label="false">不启用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="中转-添加思考">
                  <el-radio-group v-model="config.addThinking">
                    <el-radio label="true">启用</el-radio>
                    <el-radio label="false">不启用</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-scrollbar>
            </el-tab-pane>
          </el-tabs>
          <el-form-item class="box-btn">
            <el-button type="primary" @click="submitConfig">保存</el-button>
            <el-button @click="closeDialog" class="close">关闭</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
      v-model="nameVisible"
      title="修改昵称"
      width="380px"
      align-center
      custom-class="set-dialog"
    >
      <el-input
        ref="nicknameRef"
        v-model="nickName"
        placeholder="输入新昵称"
        minlength="4"
        maxlength="16"
      />
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="nameVisible = false">取消</el-button>
          <el-button type="primary" @click="submitName"> 确认 </el-button>
        </div>
      </template>
    </el-dialog>
    <avatar-upload
      ref="avatarUploadRef"
      v-if="avatarUploadVisible"
      :user="user"
      @change-avatar="changeAvatar"
    >
    </avatar-upload>
  </div>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";
import { vipMobiles } from "../../../../utils/vipMobile";
import md5 from "js-md5";
import { defaultTheme } from "../../../../utils/constaints";
import avatarUpload from "./avatarUpload.vue";
export default {
  components: {
    draggable: VueDraggableNext,
    avatarUpload,
  },
  props: {
    configActive: { type: String, default: "config" },
    closeConfig: { type: Function, default: undefined },
    editorRefresh: { type: Function, default: undefined },
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      activeName: this.configActive || "config",
      toolbarButtons: [
        { label: "粗体", value: "bold", checked: true },
        { label: "非常重要", value: "btn2", checked: true },
        { label: "重要内容", value: "btn1", checked: false },
        // { label: "有点重要", value: "btn3", checked: true },
        { label: "段落格式", value: "paragraphFormat", checked: false },
        { label: "斜体", value: "italic", checked: false },
        { label: "删除线", value: "strikeThrough", checked: false },
        { label: "字体", value: "fontFamily", checked: false },
        { label: "字体大小", value: "fontSize", checked: false },
        { label: "字体颜色", value: "textColor", checked: false },
        { label: "背景颜色", value: "backgroundColor", checked: false },
        { label: "清除格式", value: "clearFormatting", checked: true },
        { label: "出处", value: "ttips", checked: false },
        { label: "插入图片", value: "timage", checked: false },
        { label: "插入数学公式", value: "tmath", checked: false },
        { label: "缩进", value: "indent", checked: false },
        { label: "行间距", value: "lineHeight", checked: false },
        { label: "段间距", value: "segmentSpacing", checked: false },
        { label: "有序列表", value: "formatOL", checked: false },
        { label: "无序列表", value: "formatUL", checked: false },
        { label: "分割线", value: "insertHR", checked: false },
        { label: "超链接", value: "insertLink", checked: false },
        { label: "撤销", value: "undo", checked: false },
        { label: "重做", value: "redo", checked: false },
        { label: "剪切", value: "cut", checked: false },
        { label: "复制", value: "copy", checked: false },
        { label: "中转", value: "transfer", checked: false },
        // { label: "AI 助手", value: "robots", checked: false },
        { label: "闪念", value: "flashThought", checked: false },
      ],
      menuButtons: [
        { label: "新建模型", value: "append", checked: true },
        { label: "思维导图", value: "changeMindMap", checked: true },
        { label: "新开网页", value: "newTab", checked: false },
        { label: "重新命名", value: "rename", checked: true },
        { label: "模型分组", value: "group", checked: false },
        { label: "删除模型", value: "remove", checked: true },
        { label: "移动模型", value: "moveNode", checked: false },
        { label: "复制模型", value: "copyNode", checked: false },
        { label: "切换图标", value: "iconChange", checked: false },
        { label: "一键展开", value: "expandChild", checked: false },
        { label: "一键收缩", value: "reduceAll", checked: false },
        // { label: "段落记录", value: "paragraphHistory", checked: false },
      ],
      navbarButtons: [
        { label: "LOGO", value: "logo", checked: true },
        { label: "保存", value: "save", checked: true },
        { label: "分享", value: "share", checked: true },
        // { label: "导图模式", value: "mindmap", checked: true },
        { label: "全屏", value: "fullScreen", checked: true },
        { label: "设置", value: "setting", checked: true },
      ],
      editorMenuButtons: [
        { label: "粘贴", value: "paste", checked: false },
        { label: "分割线", value: "embInsertHR", checked: false },
        { label: "出处", value: "tips", checked: true },
        { label: "图片", value: "image", checked: true },
        { label: "公式", value: "math", checked: false },
        { label: "时间", value: "today", checked: false },
        { label: "撤销", value: "undo", checked: true },
        { label: "重做", value: "redo", checked: true },
      ],
      mindMenuButtons: [
        { label: "添加模型", value: "addChild", checked: false },
        { label: "删除模型", value: "delete", checked: true },
        { label: "标记重点", value: "changeIconType", checked: true },
        { label: "剪切模型", value: "cut", checked: false },
        { label: "复制模型", value: "copy", checked: false },
        { label: "粘贴模型", value: "paste", checked: false },
        { label: "展开所有", value: "expandAll", checked: true },
        { label: "从它展开", value: "changeRoot", checked: true },
        // { label: "AI关联词", value: "aiRelatedWords", checked: false },
        // { label: "AI例句", value: "aiExSentence", checked: false },
      ],
      config: {
        themeType: "dark",
        theme: defaultTheme,
        mindThemeColor: "blue",
        isWideScreen: "default",
        isTitleLine: "false",
        scrollPosition: "false",
        toolbarType: "default",
        segmentSpacing: 0.8,
        lineHeight: 1.5,
        pastePlain: "true",
        toolbarButtons: [],
        menuButtons: [],
        navbarButtons: [],
        mindCollapse: 3,
        mindhGap: 30,
        mindvGap: 10,
        edgeEnabled: "false",
        mindEdgeShowLabel: "false",
        mindHideEdgeLink: "true",
        mindToolbarPosition: "bottom",
        alwaysMind: "false",
        alwaysEditor: "true",
        mindQuadtreeType: "ver",
        textColor: "#de4e4e",
        textColorBg: null,
        textBgColor: "#3C373B",
        textBgBgColor: "#f4e6b9",
        editorMenuButtons: [],
        mindMenuButtons: [],
        chatgpt: "false",
        shortcutMenu: {},
        relationList: [],
        fontSize: 15,
        aiEnglish: "false",
        addThinking: "true",
      },
      shortcutMenu: {
        location: "bottom",
        viewLocation: "right",
        flashThought: "false",
        chatgpt: "false",
        chatgptToken: "",
        iframe: "",
        transfer: "true",
      },
      lockPassword: null,
      tipsinput: "",
      relationInput: "",
      predefineColors: [
        "#3C373B",
        "#de4e4e",
        "#f4e6b9",
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "#056D11",
      ],
      isMobileAccount: "false",
      isWechatAccount: "false",
      isWxLogin: false,
      hasChange: false,
      lastConfig: {},
      mobile: "",
      isVip: false,
      isPwdOpen: false,
      groupNameInput: "",
      pwdBox: false,
      accountName: null,
      originPwd: null,
      password: null,
      rePassword: null,
      user: null,
      nameVisible: false,
      nickName: "",
      avatarUploadVisible: false,
      version: "1.0.0",
    };
  },
  watch: {
    // "$root.config": {
    //   handler(val, old) {
    //     if (val !== old) {
    //       this.initConfig();
    //     }
    //   },
    // },
    "$root.configVisible": {
      handler(val, old) {
        if (val == true) {
          this.$store.commit("updateisLock", "false");
          this.$store.commit("updatelockTime", 0);
          this.lockPassword = null;
          this.initConfig();
        }
      },
    },
    configActive(val, old) {
      if (val !== old) {
        this.activeName = val;
      }
    },
    "$store.state.isMobileAccount": {
      handler(val) {
        this.isMobileAccount = val === true ? "true" : "false";
      },
    },
    "$store.state.isWechatAccount": {
      handler(val) {
        this.isWechatAccount = val === true ? "true" : "false";
      },
    },
  },
  created() {
    this.version = process.env.VUE_APP_VERSION;
    this.isMobileAccount = this.$store.state.isMobileAccount;
    this.isWechatAccount = this.$store.state.isWechatAccount;
    this.user = JSON.parse(localStorage.getItem("__user"));
    if (this.user) {
      let phone = this.user ? this.user.mobile : null;
      this.isPwdOpen = this.user ? this.user.isPwdOpen : false;
      this.isVip = phone ? vipMobiles.includes(phone.toString()) : false;
      this.mobile = phone
        ? phone.replace(/^(\d{3})\d*(\d{4})$/, "$1****$2")
        : "";
      if (!this.user.headImage || this.user.headImage === "") {
        this.user.headImage =
          "https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/d6ccddf0bd034dcf8396e742d094a779.jpg";
      }
      if (this.isVip) {
        this.toolbarButtons.push({
          label: "AI 助手",
          value: "robots",
          checked: false,
        });
        // this.menuButtons.push({
        //   label: "AI翻译",
        //   value: "aiTranslate",
        //   checked: false,
        // });
      }
    }
    this.initConfig();
    // console.log(user.mobile, this.mobile.toString(), "是否vip:" + this.isVip);
    // let script = document.createElement("script");
    // script.type = "text/javascript";
    // script.id = "scriptWx";
    // script.src = "//res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    // document.body.appendChild(script);
  },
  beforeUnmount() {
    // document.getElementById("scriptWx").remove();
  },
  mounted() {},
  methods: {
    async onAddGroup() {
      if (!this.groupNameInput) return;
      this.$root.addGroup({
        id: null,
        groupName: this.groupNameInput,
        sort: 0,
      });
      this.groupNameInput = "";
    },
    async onDeleteGroup(item) {
      this.$root.deleteGroup(item);
    },
    async onLoginOut() {
      this.$root.logout();
      this.$emit("update:show", false);
    },
    closeDialog() {
      if (this.updateTip) {
        this.$root.fontList = [...this.$root.fontList];
      }
      if (this.updateRelation) {
        this.$root.relationList = [...this.$root.relationList];
      }
      this.updateTip = false;
      this.updateRelation = false;
      this.$emit("update:show", false);
    },
    async initConfig() {
      let config = { ...this.$root.config };
      let menuButtons = [];
      for (let key in config.menuButtons) {
        if (config.menuButtons[key]) {
          menuButtons.push(key);
        }
      }
      this.config = config;
      this.lastConfig = config;
      // console.log(this.config);
      if (this.config.toolbarButtons && this.config.toolbarButtons.length > 0) {
        for (let i = 0; i < this.toolbarButtons.length; i++) {
          this.toolbarButtons[i].checked = false;
          for (let j = 0; j < this.config.toolbarButtons.length; j++) {
            if (this.toolbarButtons[i].value == this.config.toolbarButtons[j]) {
              this.toolbarButtons[i].checked = true;
              break;
            }
          }
        }
      }
      if (this.config.menuButtons) {
        for (let i = 0; i < this.menuButtons.length; i++) {
          this.menuButtons[i].checked =
            !!this.config.menuButtons[this.menuButtons[i].value];
        }
      }
      if (this.config.navbarButtons) {
        for (let i = 0; i < this.navbarButtons.length; i++) {
          this.navbarButtons[i].checked =
            !!this.config.navbarButtons[this.navbarButtons[i].value];
        }
      }

      if (
        this.config.editorMenuButtons &&
        this.config.editorMenuButtons.length > 0
      ) {
        for (let i = 0; i < this.editorMenuButtons.length; i++) {
          this.editorMenuButtons[i].checked = false;
          for (let j = 0; j < this.config.editorMenuButtons.length; j++) {
            if (
              this.editorMenuButtons[i].value ===
              this.config.editorMenuButtons[j]
            ) {
              this.editorMenuButtons[i].checked = true;
              break;
            }
          }
        }
      }
      if (
        this.config.mindMenuButtons &&
        this.config.mindMenuButtons.length > 0
      ) {
        for (let i = 0; i < this.mindMenuButtons.length; i++) {
          this.mindMenuButtons[i].checked = false;
          for (let j = 0; j < this.config.mindMenuButtons.length; j++) {
            if (
              this.mindMenuButtons[i].value === this.config.mindMenuButtons[j]
            ) {
              this.mindMenuButtons[i].checked = true;
              break;
            }
          }
        }
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "themeType")) {
        this.config.themeType = "dark";
      }
      if (
        !Object.prototype.hasOwnProperty.call(this.config, "mindThemeColor")
      ) {
        this.config.mindThemeColor = "blue";
      }
      if (Object.prototype.hasOwnProperty.call(this.config, "shortcutMenu")) {
        Object.keys(this.config.shortcutMenu)
          .filter((key) => key in this.shortcutMenu)
          .forEach((key) => {
            this.shortcutMenu[key] = this.config.shortcutMenu[key];
          });
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "mindCollapse")) {
        this.config.mindCollapse = 3;
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "mindhGap")) {
        this.config.mindhGap = 30;
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "mindvGap")) {
        this.config.mindvGap = 10;
      }
      if (
        !Object.prototype.hasOwnProperty.call(this.config, "mindEdgeShowLabel")
      ) {
        this.config.mindEdgeShowLabel = "false";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "edgeEnabled")) {
        this.config.edgeEnabled = "false";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "alwaysMind")) {
        this.config.alwaysMind = "false";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "alwaysEditor")) {
        this.config.alwaysEditor = "true";
      }
      if (
        !Object.prototype.hasOwnProperty.call(this.config, "mindHideEdgeLink")
      ) {
        this.config.mindHideEdgeLink = "true";
      }
      // if (
      //   !Object.prototype.hasOwnProperty.call(
      //     this.config,
      //     "mindToolbarPosition"
      //   )
      // ) {
      //   this.config.mindToolbarPosition = "bottom";
      // }
      // 导图工具栏只提供底部选择
      this.config.mindToolbarPosition = "bottom";

      if (!Object.prototype.hasOwnProperty.call(this.config, "textColor")) {
        this.config.textColor = "#de4e4e";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "textColorBg")) {
        this.config.textColorBg = null;
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "textBgColor")) {
        this.config.textBgColor = "#3C373B";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "textBgBgColor")) {
        this.config.textBgBgColor = "#f4e6b9";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "isWideScreen")) {
        this.config.isWideScreen = "default";
      }
      if (
        this.config.isWideScreen === "false" ||
        this.config.isWideScreen === "true"
      ) {
        this.config.isWideScreen = "default";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "isTitleLine")) {
        this.config.isTitleLine = "false";
      }
      if (
        !Object.prototype.hasOwnProperty.call(this.config, "scrollPosition")
      ) {
        this.config.scrollPosition = "false";
      }

      if (
        !Object.prototype.hasOwnProperty.call(this.config, "segmentSpacing")
      ) {
        this.config.segmentSpacing = 0.8;
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "lineHeight")) {
        this.config.lineHeight = 1.5;
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "fontSize")) {
        this.config.fontSize = 15;
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "chatgpt")) {
        this.config.chatgpt = "false";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "toolbarRobots")) {
        this.config.toolbarRobots = "false";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "addThinking")) {
        this.config.addThinking = "true";
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "lock")) {
        this.config.lock = {
          status: "false",
          lockedTime: "300",
          lockPassword: null,
        };
      }
      if (!Object.prototype.hasOwnProperty.call(this.config, "aiEnglish")) {
        this.config.aiEnglish = "false";
      }
    },
    refresh() {
      this.reload();
    },
    submitConfig() {
      if (!document.body.classList.contains("theme-" + this.config.theme)) {
        document.body.setAttribute("class", "theme-" + this.config.theme);
      }
      this.config.toolbarButtons = [];
      this.config.menuButtons = [];
      this.config.navbarButtons = [];
      this.config.editorMenuButtons = [];
      this.config.mindMenuButtons = [];

      for (let i = 0; i < this.toolbarButtons.length; i++) {
        if (this.toolbarButtons[i].checked === true) {
          this.config.toolbarButtons.push(this.toolbarButtons[i].value);
        }
      }
      if (this.config.toolbarButtons.length < 1) {
        this.$message.error("存量设置-存量工具栏按钮-请至少选择一个按钮功能");
        return false;
      }
      for (let i = 0; i < this.menuButtons.length; i++) {
        if (this.menuButtons[i].checked === true) {
          this.config.menuButtons.push(this.menuButtons[i].value);
        }
      }
      for (let i = 0; i < this.navbarButtons.length; i++) {
        if (this.navbarButtons[i].checked === true) {
          this.config.navbarButtons.push(this.navbarButtons[i].value);
        }
      }
      if (this.config.menuButtons.length < 1) {
        this.$message.error("菜单设置-模型树菜单-请至少选择一个菜单功能");
        return false;
      }
      for (let i = 0; i < this.editorMenuButtons.length; i++) {
        if (this.editorMenuButtons[i].checked === true) {
          this.config.editorMenuButtons.push(this.editorMenuButtons[i].value);
        }
      }
      if (this.config.editorMenuButtons.length < 1) {
        this.$message.error("存量设置-存量右键菜单设置-请至少选择一个菜单功能");
        return false;
      }
      for (let i = 0; i < this.mindMenuButtons.length; i++) {
        if (this.mindMenuButtons[i].checked === true) {
          this.config.mindMenuButtons.push(this.mindMenuButtons[i].value);
        }
      }
      if (this.config.mindMenuButtons.length < 1) {
        this.$message.error("导图设置-导图右键菜单设置-请至少选择一个菜单功能");
        return false;
      }
      if (this.config.toolbarButtons.length > 0) {
        let oddNum = this.config.toolbarButtons.findIndex(function (element) {
          return element === "ttips";
        });
        if (oddNum >= 0) {
          this.config.toolbarButtons.splice(oddNum + 1, 0, "ttips2");
        }
      }

      // 1.5.8 chatjs
      // if (this.shortcutMenu.chatgpt === "true") {
      //   if (this.shortcutMenu.iframe) {
      //     let src = this.shortcutMenu.iframe.replace(
      //       /<iframe\r?\n [^>]*src=['"]([^'"]+)[^>]*>\r?\n<\/iframe >/gi,
      //       "$1"
      //     );
      //     if (src.includes("https://chat.onechatai.cn/chatninja/")) {
      //       src = src.split("/");
      //       let token = src.pop();
      //       if (token.length == 36) {
      //         this.shortcutMenu.chatgpt = "true";
      //         this.shortcutMenu.chatgptToken = token;
      //       } else {
      //         this.shortcutMenu.chatgpt = "false";
      //       }
      //     } else {
      //       this.shortcutMenu.chatgpt = "false";
      //     }
      //   } else {
      //     this.shortcutMenu.chatgpt = "false";
      //   }
      // }

      this.config.shortcutMenu = this.shortcutMenu;
      if (this.config.toolbarRobots !== "true") {
        let index = this.config.toolbarButtons.indexOf("robots");
        if (index !== -1) {
          this.config.toolbarButtons.splice(index, 1);
        }
      }
      // if (this.config.toolbarRobots === "true" && this.isVip === true) {
      //   this.config.toolbarButtons.push("robots");
      // }
      if (this.config.shortcutMenu.flashThought !== "true") {
        let index = this.config.toolbarButtons.indexOf("flashThought");
        if (index !== -1) {
          this.config.toolbarButtons.splice(index, 1);
        }
      }
      if (this.config.lock.status === "true") {
        if (this.lockPassword) {
          this.config.lock.lockPassword = md5(this.lockPassword);
        } else if (!this.config.lock.lockPassword) {
          this.$message.error("账号设置-启用自动锁屏时必须输入锁屏密码");
          return false;
        }
      } else {
        this.config.lock.lockPassword = null;
      }
      localStorage.setItem("lockedTime", this.config.lock.lockedTime);
      this.config.toolbarButtons.push("setting");
      this.$root.relationList = [...this.$root.relationList];
      this.config.relationList = this.$root.relationList;
      this.config.fontList = this.$root.fontList;
      var config = JSON.stringify(this.config);
      this.$api.config
        .saveConfig({
          configType: 1,
          config: config,
        })
        .then((res) => {
          this.$message.success("保存成功");
          // that.editorRefresh();
          // this.closeConfig();
        });
      let p = this.config;
      let menuButtons = {};
      p.menuButtons.forEach((key, idx) => {
        menuButtons[p.menuButtons[idx]] = true;
      });
      p.menuButtons = menuButtons;
      let navbarButtons = {};
      p.navbarButtons.forEach((key, idx) => {
        navbarButtons[p.navbarButtons[idx]] = true;
      });
      p.navbarButtons = navbarButtons;
      this.$root.updateConfig(p);

      this.closeDialog();
    },
    focusfont(item) {
      this.$root.fontList.forEach((element) => {
        element.isfouce = false;
      });
      item.isfouce = true;
    },
    focusRelation(item) {
      this.$root.relationList.forEach((element) => {
        element.isfouce = false;
      });
      item.isfouce = true;
    },
    focusGroups(item) {
      this.$root.groups.forEach((element) => {
        element.isfouce = false;
      });
      item.isfouce = true;
    },
    saveTips() {
      this.updateTip = true;
      var sort = 0;
      if (this.$root.fontList.length > 0) {
        // sort = this.fontlist.length;
        sort = this.$root.fontList[0].sort - 1;
      }
      this.$api.article
        .saveTip({
          tip: this.tipsinput,
          sort: sort,
        })
        .then((res) => {
          // console.log("1");
          if (res.isSuccess === false) {
            this.$message.error(res.message);
          } else {
            this.$root.fontList.unshift({
              sort: sort,
              id: res.data,
              tip: this.tipsinput,
            });
            this.$root.fontList.forEach((item, idx) => {
              item.sort = idx;
            });
            this.tipsinput = "";
          }
        });
    },
    saveRelation() {
      if (this.$root.relationList.length >= 10) {
        return this.$root.tips("error", "最大可保存10条关系！");
      }
      this.updateRelation = true;
      var sort = 0;
      if (this.$root.relationList.length > 0) {
        sort = this.$root.relationList[0].sort - 1;
      }
      this.$root.relationList.unshift({
        id: new Date().getTime() + Math.random().toString(36).slice(2),
        data: this.relationInput,
        sort: sort,
      });
      this.$root.relationList.forEach((item, idx) => {
        item.sort = idx;
      });
      this.relationInput = "";
    },
    removeTip(id) {
      this.updateTip = true;
      this.$api.article.removeTip(id).then((res) => {
        let idx = this.$root.fontList.findIndex((r) => r.id === id);
        if (idx !== -1) {
          this.$root.fontList.splice(idx, 1);
        }
      });
    },
    removeRelation(id) {
      this.updateRelation = true;
      let idx = this.$root.relationList.findIndex((r) => r.id === id);
      if (idx !== -1) {
        this.$root.relationList.splice(idx, 1);
      }
    },
    async datadragEnd(evt) {
      this.updateTip = true;
      evt.preventDefault();
      // console.log("拖动前的索引 :" + evt.oldIndex);
      // console.log("拖动后的索引 :" + evt.newIndex);
      // console.log(evt);
      // console.log(this.fontlist);
      for (let i = 0; i < this.$root.fontList.length; i++) {
        let res = await this.$api.article.saveTip({
          tipId: this.$root.fontList[i].id,
          tip: this.$root.fontList[i].tip,
          sort: i,
        });
        this.$root.fontList[i].sort = i;
      }
    },
    relationDragEnd(evt) {
      this.updateRelation = true;
      evt.preventDefault();
      for (let i = 0; i < this.$root.relationList.length; i++) {
        this.$root.relationList[i].sort = i;
      }
    },
    async groupsDragEnd(evt) {
      this.updateRelation = true;
      evt.preventDefault();
      for (let i = 0; i < this.$root.groups.length; i++) {
        await this.$root.updateGroup({
          id: this.$root.groups[i].id,
          groupName: this.$root.groups[i].groupName,
          sort: i,
        });
        this.$root.groups[i].sort = i;
      }
      await this.$root.getAllGroup();
    },
    wxGetCode() {
      if (this.isWxLogin == false) {
        setTimeout(() => {
          this.isWxLogin = true;
        }, 500);
      } else {
        var tab = { props: { name: "bindAccount" } };
        this.tabsHandleClick(tab);
        setTimeout(() => {
          this.isWxLogin = true;
        }, 500);
      }
    },
    tabsHandleClick(tab, event) {
      switch (tab.props.name) {
        case "bindAccount":
          // var redirect_uri = encodeURIComponent(
          //   window.location.origin + "/wechat"
          // );
          var redirect_uri = encodeURIComponent(
            "https://wx-login.moxingshu.com.cn/wechat"
          );
          // console.log(redirect_uri);
          var obj = new WxLogin({
            self_redirect: true,
            id: "login_container",
            appid: "wx20ce863c038c889c",
            scope: "snsapi_login",
            redirect_uri: redirect_uri,
            state: "123131",
            style: "",
            href: window.location.origin + "/pc/css/wechat.css",
          });
          break;
        case "tips":
          this.getTips();
          break;
      }
    },
    getTips() {
      // console.log(this.$root.fontList);
      this.$api.article.TipList().then((res) => {
        this.$root.fontList = [...res];
      });
    },
    updateBindWechat() {
      this.isWechatAccount = "true";
    },
    async updateGroup(item) {
      await this.$root.updateGroup({
        id: item.id,
        groupName: item.groupName,
        sort: item.sort,
      });
      item.isEdit = false;
    },
    async onGroupEdit(item) {
      // if (item.isEdit) {
      //   await this.$root.updateGroup({
      //     id: item.id,
      //     groupName: item.groupName,
      //     sort: item.sort,
      //   });
      // } else {
      setTimeout(() => {
        // this.$refs[item.id].focus();
        const dom = document.getElementById(item.id);
        dom.focus();
      }, 100);
      // }

      item.isEdit = !item.isEdit;
    },
    async onRelationEdit(item) {
      if (item.isEdit) {
        this.updateRelation = true;
      } else {
        setTimeout(() => {
          // this.$refs[item.id].focus();
          var dom = document.getElementById("relation" + item.id);
          dom.focus();
        }, 100);
      }
      item.isEdit = !item.isEdit;
    },
    onTipsFocus(item) {
      item.old = item.tip;
    },
    onEnter(item) {
      const dom = document.getElementById("tips" + item.id);
      dom.blur();
    },
    async onTipsEdit(item) {
      if (item.isEdit) {
        if (item.old !== item.tip) {
          await this.$api.article.saveTip({
            tipId: item.id,
            tip: item.tip,
            sort: item.sort,
          });
        }
        this.updateTip = true;
      } else {
        setTimeout(() => {
          // this.$refs[item.id].focus();
          const dom = document.getElementById("tips" + item.id);
          dom.focus();
        }, 100);
      }
      item.isEdit = !item.isEdit;
    },
    pwdLogin() {
      this.pwdBox = !this.pwdBox;
    },
    async submitPwdOpen() {
      this.accountName = this.user.mobile;
      var regexpName = /^[a-zA-Z0-9_-]{8,16}$/;
      var regexpP = /^[^\u4e00-\u9fa5]+$/;
      var regexpPwd = /^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{8,16}$/;
      if (this.isPwdOpen) {
        if (
          !this.originPwd ||
          this.originPwd.length < 8 ||
          this.originPwd.length > 16
        ) {
          // this.$refs.password.focus();
          this.$refs.originPwd.select();
          return this.$root.tips("error", "输入正确的原密码");
        }
      } else {
        if (!regexpName.test(this.accountName)) {
          // this.$refs.accountName.focus();
          this.$refs.accountName.select();
          return this.$root.tips(
            "error",
            "输入由英文字母或数字组成的8~16位用户名"
          );
        }
      }
      if (!regexpP.test(this.password) || !regexpPwd.test(this.password)) {
        // this.$refs.password.focus();
        this.$refs.password.select();
        return this.$root.tips(
          "error",
          "输入至少包含字母、数字、特殊字符任意两种组成的8~16位密码"
        );
      }
      if (this.rePassword !== this.password) {
        // this.$refs.rePassword.focus();
        this.$refs.rePassword.select();
        return this.$root.tips("error", "两次输入的密码不同，请输入相同密码");
      }
      if (this.isPwdOpen) {
        let res = await this.$api.account.updatePwd({
          originPwd: this.originPwd,
          password: this.password,
        });
        if (!res.data.isSuccess) {
          return this.$root.tips("error", res.data.message);
        }
        this.$root.tips("success", "密码修改成功");
        this.originPwd = "";
      } else {
        let res = await this.$api.account.checkAccountName({
          accountName: this.accountName,
        });
        if (!res.data.isSuccess) {
          return this.$root.tips("error", res.data.message);
        }
        res = await this.$api.account.addPwd({
          accountName: this.accountName,
          password: this.password,
        });
        if (!res.data.isSuccess) {
          return this.$root.tips("error", res.data.message);
        }
        this.$root.tips("success", "密码登录开启成功");
        this.accountName = "";
        this.user.isPwdOpen = true;
        this.isPwdOpen = true;
        this.$store.commit("setUser", this.user);
      }
      this.password = "";
      this.rePassword = "";
      this.pwdLogin();
    },
    changeName() {
      this.nickName = this.user.nickName;
      this.nameVisible = true;
      setTimeout(() => {
        this.$refs.nicknameRef.select();
      }, 100);
    },
    async submitName() {
      let res = await this.$api.account.updateNickname({
        nickName: this.nickName,
      });
      if (!res.data.isSuccess) {
        return this.$root.tips("error", res.data.message);
      }
      this.user.nickName = this.nickName;
      this.$store.commit("setUser", this.user);
      this.nameVisible = false;
    },
    changeAvatar(url) {
      this.user.headImage = url;
      this.$store.commit("setUser", this.user);
      this.avatarUploadVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.set-box {
  position: relative;
  width: 720px;
  height: 580px;
}

:deep .el-dialog.el-dialog__header {
  padding: 0 !important;
}

:deep .el-dialog.el-dialog__body {
  padding: 0 !important;
}

:deep .el-tabs--left .el-tabs__header.is-left {
  width: 146px !important;
}

:deep .el-tabs__content {
  padding: 8px 48px 0 48px;
}

.box-btn {
  position: absolute;
  left: 168px;
  bottom: 40px;
}

:deep .el-checkbox__inner {
  border-radius: 50%;
}

:deep .sortable-chosen {
  background: #ddedff;
}

.tips-infobox {
  background: rgba(19, 128, 255, 0.16);
  border-radius: 2px;
  display: flex;
  height: 42px;
  padding: 0 10px;
  line-height: 42px;

  :deep .el-icon {
    vertical-align: -16%;
  }
}

.tips-info {
  width: 410px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333333;

  :deep .el-input {
    width: 200px;
  }

  .group-input :deep .el-input__wrapper {
    color: #ebebeb;
    background-color: var(--color-body);
    box-shadow: 0 0 0 1px var(--color-body) inset;
  }
}

.tip-tip {
  padding: 0 10px;
  height: 42px;
  line-height: 42px;
}

.menu-scrollbar {
  width: 420px;
}

.color-block {
  display: flex;
  align-items: center;
  margin-right: 36px;
}

.demonstration {
  margin-right: 16px;
}
.acc-box {
  width: 100%;
  padding: 0 12px;
  background-color: #f4f4f5;
  border-radius: 8px;
  font-size: 12px;
}
.acc-i {
  width: 100%;
  height: 42px;
  line-height: 42px;
}
.acc-d {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.acc-l {
  float: left;
}
.acc-r {
  float: right;
  cursor: pointer;
  display: flex;
}
.acc-b {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pwd-box {
  padding: 12px 0;
  .tips-input {
    height: 32px;
  }
}
.pwd-d {
  margin-bottom: 12px;
}
[class*=" dark"] {
  .acc-box {
    background-color: #0d0d0d;
    color: #fff;
  }
}
</style>
