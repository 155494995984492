// 允许使用某些功能的用户
export const gptMobiles = [
  "18016214930",
  "15900865005",
  "13088010590",
  "15032332862",
  "19871661211",
  "13001822300",
  "15003504235",
  "18918452004",
  "18684729232",
];
export const vipMobiles = [
  "15900865005",
  "18016214930",
  "17632099365",
  "18509566257",
  "18670397708",
  "13816564316",
  "18918452004",
  "13098307996",
  "13681997436",
  "18684729232",
  "13088010590",
  "18516598994",
  "18350331853",
  "13166596198",
  "13274824299",
  "13018038936",
  "18666922812",
];
export const testMobiles = [
  "18016214930",
  "13816564316",
  "15900865005",
  "13951532168",
  "13274824299",
  "18826076426",
  "18022096003",
  "18074424191",
  "18842683792",
  "15237333553",
  "18821633518",
];
