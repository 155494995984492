<template>
  <div class="lock">
    <div class="box" v-if="passwdError">
      <svg-icon icon-class="035" class-name="svg-80" />
      <div class="passwdError">密码不正确。请再试一次。</div>
      <el-button @click="reset()" class="btn">确认</el-button>
      <div @click="onLoginOut" class="lost">忘记密码 重新登录</div>
    </div>
    <div class="box" v-else>
      <svg-icon icon-class="new-logo" class-name="svg-80" />
      <el-input
        class="lockPasswdInp"
        ref="lockPasswdInp"
        size="large"
        show-password
        placeholder="请输入密码"
        v-model="passwd"
      >
      </el-input>
      <el-button @click="unLock()" class="btn">确认</el-button>
      <div @click="onLoginOut" class="lost">忘记密码 重新登录</div>
    </div>
    <!-- <div class="logout">
      <el-tooltip effect="dark" content="退出登录" placement="bottom">
        <el-icon @click="onLoginOut"><SwitchButton /></el-icon>
      </el-tooltip>
    </div> -->
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    return {
      passwd: "",
      passwdError: false,
    };
  },
  mounted() {
    this.reset();
    document.addEventListener("keydown", this.keyDownHandle);
    document.addEventListener("contextmenu", this.contextmenuHandle);
  },
  unmounted() {
    document.removeEventListener("keydown", this.keyDownHandle);
    document.removeEventListener("contextmenu", this.contextmenuHandle);
  },
  methods: {
    // 重置初始化
    reset() {
      this.passwdError = false;
      this.passwd = "";
      this.$nextTick(() => {
        this.$refs.lockPasswdInp.focus();
      });
    },
    // 解锁
    unLock() {
      let pw = md5(this.passwd);
      if (pw != this.$root.config.lock.lockPassword) {
        return (this.passwdError = true);
      }
      this.$store.commit("updatelockTime", 0);
    },
    // 监听鼠标按下事件，阻止 F12 事件
    keyDownHandle(event) {
      if (event.keyCode == 13) {
        if (this.passwdError) {
          this.reset();
        } else {
          this.unLock();
        }
      }
      return event.keyCode !== 123 || (event.returnValue = false);
    },
    // 阻止鼠标右键事件
    contextmenuHandle(event) {
      return (event.returnValue = false);
    },
    // 退出登录
    async onLoginOut() {
      this.$root.logout();
      this.$emit("update:show", false);
    },
  },
};
</script>
<style scoped>
.lock {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  -webkit-animation: AniOpa 0.2s forwards;
  animation: AniOpa 0.2s forwards;
}
.lock .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  border-radius: 24px;
  border: 1px solid  rgba(255, 255, 255, 0.25);
  background: #0A0A0A;
  padding: 72px 80px;
}

.lockPasswdInp {
  width: 100%;
}
.lockPasswdInp :deep .el-input__wrapper {
  box-shadow: none;
  border-radius: 8px;
}
.btn {
  width: 100%;
  font-size: 16px;
  background: #fff !important;
  border-radius: 8px;
  border: 0;
  height: 40px;
  margin-top: 24px;
  color: #333;
}
.btn:hover {
  background: #eee !important;
  color: #333;
}

.passwdError {
  width: 340px;
  height: 40px;
  text-align: center;
  color: var(--color-white);
  font-size: 16px;
  line-height: 40px;
}

.confirm-btn {
  width: 70px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #fff;
  font-size: 16px;
  background-color: rgba(6, 14, 22, 0.5);
  border: 1px solid #0076c8;
  border-radius: 3px;
  cursor: pointer;
}

.confirm-btn:hover {
  background-color: rgba(6, 14, 22, 0.8);
}

.logout {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.logout :deep .el-icon {
  color: var(--color-white);
  font-size: 32px;
}
.svg-80 {
  font-size: 80px;
  margin-bottom: 4rem;
  width: 172px;
  height: 36px;
}
.lost {
  color: #777;
  font-size: 16px;
  margin-top: 26px;
  cursor: pointer;
}
:deep .el-input__wrapper {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #141414;
}
</style>
