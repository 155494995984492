import { createStore, storeKey } from "vuex";
// import axios from 'axios';
import config from "/package.json";
var userAgent = navigator.userAgent.toLowerCase();
let platfrom = "Unknown";
let version = "Unknown";
if (userAgent.indexOf("win") > -1) {
  platfrom = 1;
  version = "windows";
} else if (userAgent.indexOf("iphone") > -1) {
  platfrom = "101";
  version = userAgent.match(/cpu iphone os (.*?) like mac os/);
  version = version ? version[1] : "Unknown";
} else if (userAgent.indexOf("ipad") > -1) {
  platfrom = "101";
  version = userAgent.match(/cpu iphone os (.*?) like mac os/);
  version = version ? version[1] : "Unknown";
} else if (userAgent.indexOf("mac") > -1) {
  platfrom = "1";
  version = userAgent.match(/cpu iphone os (.*?) like mac os/);
  version = version ? version[1] : "Unknown";
} else if (
  userAgent.indexOf("x11") > -1 ||
  userAgent.indexOf("unix") > -1 ||
  userAgent.indexOf("sunname") > -1 ||
  userAgent.indexOf("bsd") > -1
) {
  platfrom = "201";
} else if (userAgent.indexOf("linux") > -1) {
  platfrom = "201";
  if (userAgent.indexOf("android") > -1) {
    version = userAgent.match(/Android.*?(?=;)/);
    version = version ? version[1] : "Unknown";
  }
}
let loginBrowser = "Unknown";
if (userAgent.indexOf("opera") > -1) {
  loginBrowser = "opera";
} else if (userAgent.indexOf("edge") > -1) {
  loginBrowser = "edge";
} else if (userAgent.indexOf("firefox") > -1) {
  loginBrowser = "firefox";
} else if (userAgent.indexOf("chrome") > -1) {
  loginBrowser = "chrome";
} else if (userAgent.indexOf("safari") > -1) {
  loginBrowser = "safari";
}
export default createStore({
  // 声明变量
  state: {
    // token
    mindmap_token: "",
    // 平台设备版本请信息|平台(1:pc,101:ios,201:android)|自定义|系统版本|
    mindmap_device:
      platfrom +
      "|" +
      config.version +
      "|" +
      version +
      "|" +
      process.env.VUE_APP_ENV +
      "|" +
      loginBrowser,
    mobile: "",
    isLogin: false,
    content: "",
    expandedList: [],
    isMobileAccount: false,
    isWechatAccount: false,
    user: undefined,

    // 无操作计时时间
    lockTime: 0,
    // 当前是否为锁屏状态
    isLock: sessionStorage.getItem("isLock") || "false",
    barExpand: false,
  },
  // 修改变量（state不能直接赋值修改，只能通过mutations）
  mutations: {
    autoLogin(state) {
      state.mindmap_token = localStorage.getItem("mindmap_token");
      let u = localStorage.getItem("__user");
      state.user = u ? JSON.parse(u) : undefined;
      state.isLogin = localStorage.getItem("isLogin");
      state.isWechatAccount = localStorage.getItem("isWechatAccount");
      state.isMobileAccount = localStorage.getItem("isMobileAccount");
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("__user", JSON.stringify(user));
    },
    setToken(state, token) {
      state.mindmap_token = token;
      localStorage.setItem("mindmap_token", token);
      localStorage.setItem("isLogin", true);
    },
    startLogin(state) {
      state.isLogin = true;
      state.lockTime = 0;
      state.isLock = false;
      sessionStorage.setItem("isLock", false);
    },
    delToken(state) {
      state.mindmap_token = null;
      state.mobile = null;
      state.user = false;
      state.lockTime = 0;
      state.isLock = false;
      sessionStorage.setItem("isLock", false);
      localStorage.removeItem("mindmap_token");
      localStorage.removeItem("__user");
      localStorage.removeItem("mobile");
      localStorage.removeItem("isLogin");
      localStorage.removeItem("isMobileAccount");
      localStorage.removeItem("isWechatAccount");
      localStorage.removeItem("__curGroup");
      localStorage.removeItem("__show_mind_map");
      localStorage.removeItem("__is_bing_mobile");
      localStorage.removeItem("__is_wx_login");
      localStorage.removeItem("__is_pwd_login");
      state.isLogin = false;
    },
    setMobile(state, mobile) {
      state.mobile = mobile;
      localStorage.setItem("mobile", mobile);
    },
    changeContent(state, content) {
      state.content = content;
    },
    changeExpandedList(state, content) {
      state.expandedList = content;
    },
    setMobileAccount(state, isMobileAccount) {
      state.isMobileAccount = isMobileAccount;
      localStorage.setItem("isMobileAccount", isMobileAccount);
    },
    setWechatAccount(state, isWechatAccount) {
      state.isWechatAccount = isWechatAccount;
      localStorage.setItem("isWechatAccount", isWechatAccount);
    },
    updatelockTime(state, lockTime) {
      state.lockTime = lockTime;
    },
    updateisLock(state, isLock) {
      state.isLock = isLock;
      sessionStorage.setItem("isLock", isLock);
    },
    updatebarExpand(state, barExpand) {
      state.barExpand = barExpand;
    },
  },
  // mutations的值由actions传入
  actions: {
    // 参数一：自带属性，参数二：新值
    // setName(context, value) {
    //   // 修改getname的值
    //   context.commit('getname', value)
    // },
    // getA(context) {
    //   // 返回一个Promise函数
    //   return new Promise((reslove, reject) => {
    //     // 请求
    //     axios.get('https://api.url').then(res => {
    //       // 修改getAData的值
    //       context.commit('getAData', res)
    //       reslove(res)
    //     }).catch(res => { reject(res) })
    //   })
    // },
    // getB(context) {
    //   return new Promise((reslove, reject) => {
    //     axios.get('https://api.url').then(res => {
    //       context.commit('getBData', res)
    //       reslove(res)
    //     }).catch(res => { reject(res) })
    //   })
    // }
  },
  modules: {},
});
