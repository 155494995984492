/**
 * api接口的统一出口
 */

// 账户模块接口
import account from "@/api/account";
// 设置模块接口
import config from "@/api/config";
// 文档历史记录 下架
import historyArticle from "@/api/historyArticle";
// 文章模块接口
import article from "@/api/article";
// 文章分享接口
import share from "@/api/share";
// 用户模块接口
import upload from "@/api/upload";
// 用户模块接口
import user from "@/api/user";
import groups from "@/api/groups";
// 其他模块接口
import other from "@/api/other";

import edges from "./edges";

// 闪念接口
import insight from "@/api/insight";

import userLog from "@/api/userLog";

import thought from "@/api/thought";

export default {
  account,
  config,
  historyArticle,
  article,
  share,
  upload,
  user,
  edges,
  other,
  insight,
  groups,
  userLog,
  thought,
};
