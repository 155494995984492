import { isWin } from "../components/antv-mindmap/mxs-mindmap.es";

export const LoginState = {
  loginIn: 0, // 正在登录
  hasLogin: 1, // 已经登陆
  noLogin: 2, // 没有登陆
  errorLogin: 99,
};

export const DragShowMode = {
  default: 0,
  full: 1,
  hide: 2,
};
export const DragCrossMode = {
  enabled: 0,
  disabled: 1,
  leftDisabled: 2,
  rightDisabled: 3,
};

export const defaultTheme = "dark7";

//配置快捷键
export const shortcutKeys = [
  {
    eventKey: "event-article",
    key: "open-left", //侧边栏
    title: "开关侧边栏",
    shortcutKey: "Meta+L",
    shortcutText: "⌘ + L",
    checkInput: true, //具有输入focus的时候快捷键不生效
    enabled: true, //是否开启快捷键
    icon: "new-69",
  },
  {
    eventKey: "event-article",
    key: "open-mind", //开关导图
    title: "开关导图",
    shortcutKey: "Meta+M",
    shortcutText: "⌘ + M",
    checkInput: false,
    enabled: true, //是否开启快捷键
    icon: "new-11",
  },
  {
    eventKey: "event-article",
    key: "open-document", //开关文档
    title: "开关文档",
    shortcutKey: "Meta+D",
    shortcutText: "⌘ + D",
    checkInput: false,
    enabled: true, //是否开启快捷键
    icon: "file",
  },
  {
    eventKey: "event-article",
    key: "doc-mind", //文档导图切换
    title: "文档导图切换",
    shortcutKey: "Meta+K",
    shortcutText: "⌘ + K",
    checkInput: false,
    enabled: true, //是否开启快捷键
    icon: "new-11",
  },
  // {
  //   eventKey: "event-article",
  //   key: "open-ai", //打开AI
  //   title: "打开AI",
  //   shortcutKey: "Meta+B",
  //   shortcutText: "⌘ + B",
  //   checkInput: false,
  //   enabled: true, //是否开启快捷键
  // },
  {
    eventKey: "event-article",
    key: "new-item", //新建模型
    title: "新建模型",
    shortcutKey: "Meta+Enter",
    shortcutText: "⌘ + Enter",
    checkInput: true,
    enabled: true, //是否开启快捷键
    icon: "new-10",
  },
  {
    eventKey: "event-article",
    key: "del-item",
    title: "删除模型",
    shortcutKey: "Meta+Delete",
    shortcutText: "⌘ + Del",
    checkInput: true,
    enabled: true,
    icon: "new-15",
  },
  {
    eventKey: "event-article",
    key: "move-item",
    title: "移动模型",
    shortcutKey: "Meta+X",
    shortcutText: "⌘ + X",
    checkInput: true,
    enabled: true,
    icon: "new-16",
  },
  {
    eventKey: "event-article",
    key: "copy-item",
    title: "复制模型",
    shortcutKey: "Meta+C",
    shortcutText: "⌘ + C",
    checkInput: true,
    enabled: true,
    icon: "new-20",
  },
];
export const mindShortcutKeys = [
  {
    eventKey: "event-mindmap",
    key: "add-item",
    title: "添加模型",
    shortcutKey: "Tab",
    shortcutText: "Tab",
    checkInput: false,
    enabled: false,
    icon: "new-10",
  },
  {
    eventKey: "event-mindmap",
    key: "del-item",
    title: "删除模型",
    shortcutKey: "Del",
    shortcutText: "Del",
    checkInput: false,
    enabled: false,
    icon: "new-15",
  },
  {
    eventKey: "event-mindmap",
    key: "move-item",
    title: "移动模型",
    shortcutKey: "Meta+X",
    shortcutText: "⌘ + X",
    checkInput: false,
    enabled: false,
    icon: "new-16",
  },
  {
    eventKey: "event-mindmap",
    key: "copy-item",
    title: "复制模型",
    shortcutKey: "Meta+C",
    shortcutText: "⌘ + C",
    checkInput: false,
    enabled: false,
    icon: "new-20",
  },
];
shortcutKeys.forEach((item) => {
  if (isWin) {
    if (item.shortcutKey) {
      item.shortcutKey = item.shortcutKey.replace("Meta", "Ctrl");
    }
    if (item.shortcutText) {
      item.shortcutText = item.shortcutText.replace("⌘", "Ctrl");
    }
  }
  item.shortcutKey = item.shortcutKey.toLowerCase();
});
mindShortcutKeys.forEach((item) => {
  if (isWin) {
    if (item.shortcutKey) {
      item.shortcutKey = item.shortcutKey.replace("Meta", "Ctrl");
    }
    if (item.shortcutText) {
      item.shortcutText = item.shortcutText.replace("⌘", "Ctrl");
    }
  }
  item.shortcutKey = item.shortcutKey.toLowerCase();
});
