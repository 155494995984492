/**
 * share模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例

const share = {
  // 获取分享链接
  share(params) {
    return axios.post(`${base.baseURL}/api/Share/Share`, params);
  },
  // 订阅或取消订阅
  subscribe(articleId, cancel) {
    return axios.post(
      `${base.baseURL}/api/Share/Subscribe/${articleId}/${cancel}`
    );
  },
  // 获取文档树
  articleTree(articleId) {
    //需要本地化处理，就不用每次服务端获取数据了
    return axios.get(`${base.baseURL}/api/Share/ArticleTree/${articleId}`);
  },
  // 文档内容详情
  articleDetail(shareId, articleId) {
    return axios.get(
      `${base.baseURL}/api/Share/ArticleDetail/${shareId}/${articleId}`
    );
  },
  // 转存
  save(shareId, catalogId = "") {
    return axios.post(`${base.baseURL}/api/Share/Save/${shareId}/${catalogId}`);
  },
  // 是否允许转存
  enableSaveShare(shareId) {
    return axios.get(`${base.baseURL}/api/Share/EnableSaveShare/${shareId}`);
  },
};
export default share;
